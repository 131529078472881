<template>
  <initUserId
    :image="'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expoApplent/signPage.png'"
  ></initUserId>
</template>

<script>
import initUserId from "~/baseComponents/baseSign/initUserId";
export default {
  components: { initUserId },
};
</script>

<style>
</style>